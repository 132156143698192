<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr class="list-clickable">
                <td v-bind:class="{'font-weight-bold': item.Status != 1}" @click="gotoDetail(item.ID, item.OrderID, item.OrderType)">{{ item.CreatedDate | display_date('DD-MM-YYYY')}}
                  <br />
                  {{ item.CreatedDate | display_date('HH:mm:ss')}}</td>
                <td v-bind:class="{'font-weight-bold': item.Status != 1}" @click="gotoDetail(item.ID, item.OrderID, item.OrderType)">{{ senderName(item.SenderID, item.SenderUsername) }}</td>
                <td class="text-end" v-bind:class="{'font-weight-bold': item.Status != 1}" @click="gotoDetail(item.ID, item.OrderID, item.OrderType)">{{ item.OrderID }}</td>
                <td v-bind:class="{'font-weight-bold': item.Status != 1}">
                  <a 
                  target="_blank" 
                  :href="getUrlDetail(item.OrderID, item.OrderType)"
                  @click="updateNotificationStatus(item.ID)"
                  @click.middle="updateNotificationStatus(item.ID)"
                  >{{item.Message}}</a>
                </td>
                <td class="text-center">
                  <a target="_blank" :href="getUrlDetail(item.OrderID, item.OrderType)"
                  @click="updateNotificationStatus(item.ID)"
                  @click.middle="updateNotificationStatus(item.ID)" style="text-decoration: none;"><i role="button" medium="" class="v-icon notranslate mr-2 v-icon--link material-icons theme--light fa fa-external-link-alt"></i></a>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
  components: {
    "hpo-filter": Filtering
  },
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      headers: [
        {
          text: "Ngày tạo",
          value: "CreatedDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: "Người gửi",
          value: "SenderUsername",
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Mã đơn hàng",
          value: "OrderID",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "Nội dung",
          value: "Message",
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Thao tác",
          value: "",
          align: "center",
          sortable: false,
          filterable: false
        }
      ],
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "notification_list",
      filterConditions: [],
      quickFilterConditions: [],
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."]
    };
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions)
          ? filterConditions[this.viewName]
          : [];
        this.filter_data();
      },
      deep: true
    }
  },
  computed: mapState({
    items: state => state.notification.all.data,
    total_rows: state => state.notification.all.total,
    loading: state => state.notification.loading,
    staffs: state => state.commons.all_users
  }),
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      this.$store.dispatch("notification/getList", param);
    },
    getUrlDetail: function(orderId, orderType) {
      if (!orderType || orderType == 1) {
        return '/don-hang/' + orderId;
      } 
      else if(orderType == 3) {
        return '/khach-hang/lich-su-rut-tien'
      }
      else if(orderType == 4) {
        return '/khieu-nai/' + orderId;
      }
      else {
        return '/ky-gui/' + orderId;
      }
    },
    updateNotificationStatus: function(id) {
      let item = _.find(this.items, {'ID': id});
      this.$store.dispatch("notification/setDetail", {
        'data': {'ID': id, 'Status': 1},
        'id': id
      });
      item.Status = 1;
    },
    gotoDetail: function(id, orderId, orderType) {
        let item = _.find(this.items, {'ID': id});
        this.$store.dispatch("notification/setDetail", {
          'data': {'ID': id, 'Status': 1},
          'id': id
        });
        item.Status = 1;
        if (!orderType || orderType == 1) {
            let routeData = this.$router.resolve({
            name: "OrderDetail",
            params: {
                Pid: orderId,
                title: `Chi tiết đơn hàng (#${orderId})`
            }
            });
            window.open(routeData.href, "_blank");
        } else if (orderType == 3) {
            let routeData = this.$router.resolve({
            name: "WithdrawHistory"
            });
            window.open(routeData.href, "_blank");
        } else {
            let routeData = this.$router.resolve({
            name: "DepositDetail",
            params: {
                Pid: orderId,
                title: `Chi tiết đơn hàng (#${orderId})`
            }
            });
            window.open(routeData.href, "_blank");
        }
    },
    deleteItem: function(id) {
      window.getApp.$emit("REQUEST_DELETE_DATA", id);
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    senderName(senderId, senderName) {
      const staff = _.find(this.staffs, { ID: senderId });
      return staff ? staff.FullName : senderName;
    }
  },
  mounted() {
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
      this.$store.dispatch("notification/removeDetail", id);
      setTimeout(this.filter_data, 200);
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$off("CONFIRMED_REMOVE_DATA");
  }
};
</script>